import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ZuffGPT = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="ZuffGPT; or, How to Survive AI" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>ZuffGPT; or, How to Survive AI</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Apr. 29th, 2023) </p>
        </div>
        <div className="articleBody">
          <p>
            This AI discourse is fast approaching its critical mass at this
            point so I obviously gotta add my 2 trillion cents.
          </p>
          <p>
            Before I do, I should establish my credibility. My first direct
            exposure to AI was in late 2017 when I was still in Illuminati
            School (also known as Fartmouth College). I took a course called{" "}
            <i>Cognitive Computing with Watson</i> with a{" "}
            <a
              href="https://researcher.watson.ibm.com/researcher/view.php?person=us-ccpalmer"
              target="_blank"
              rel="noopener noreferrer"
            >
              cool professor
            </a>{" "}
            who’s also a famous researcher at IBM (hence how he got all of us
            the hookup to a suite of AI tools that were extremely exclusive and
            prohibitively expensive at the time).{" "}
          </p>
          <p>
            During that course I did the very serious work of training a machine
            learning model to differentiate between a{" "}
            <a
              href="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.aws.toolstation.com%2Fimages%2F141020-UK%2F800%2F15650.jpg&f=1&nofb=1&ipt=9ae7315983bcef0ed80f2bb682279bbf7d9371bf5cc9196c1d4f05618edc3785&ipo=images"
              target="_blank"
              rel="noopener noreferrer"
            >
              mop
            </a>{" "}
            and a{" "}
            <a
              href="https://en.wikipedia.org/wiki/Komondor"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hungarian sheepdog
            </a>{" "}
            , and training another model to differentiate between a{" "}
            <a
              href="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fexternal-preview.redd.it%2F6vh7N3NdpsM-KY4cjbhD2fwhobZdybsTW591zoxlPas.jpg%3Fauto%3Dwebp%26s%3D65e7e527e08fc4bb25ad175d93dd3036b1636b3b&f=1&nofb=1&ipt=4a54e0ddd28404bee1c3627347be763eb2c6fa897c69d745976bb3eba40de510&ipo=images"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chihuahua and a blueberry muffin
            </a>
            . In other words I was taught well enough to understand the general
            history and principles of AI, and how to use off-the-shelf AI
            tooling to demonstrate amusing canine-related Internet memes.
          </p>
          <p>
            Around that same time, I went to an exhibition at{" "}
            <a
              href="https://www.icaboston.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ICA/Boston
            </a>{" "}
            which is where I first encountered the{" "}
            <a
              href="https://bostonartreview.com/reviews/internet-view-trevor-paglen-ica/"
              target="_blank"
              rel="noopener noreferrer"
            >
              genius work of Trevor Paglen
            </a>{" "}
            (my personal favorite is his{" "}
            <a
              href="https://en.wikipedia.org/wiki/Eigenface"
              target="_blank"
              rel="noopener noreferrer"
            >
              eigenface
            </a>{" "}
            of{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/poet-foreigner-prisoner"
              target="_blank"
              rel="noopener noreferrer"
            >
              Fanon
            </a>
            ), and when I first became consciously aware of the possibility of
            using AI to generate art.{" "}
          </p>
          <p>
            I should underline that I came nowhere <i>near</i> making <i>any</i>{" "}
            contribution to the kind of AI research that’s resulted in the
            current boom in tools like ChatGPT, DALL-E, and Bard. Nor did I
            actually try my hand at making any AI-assisted art, despite how cool
            I thought the concept was. All I’m saying is that I’ve been
            contemplating this stuff for a while and I’m not completely talking
            out my ass.{" "}
          </p>
          <p>
            A lot has already been said about what the implications of AI will
            be on art and artists. It’s been painted by many as a doomsday
            scenario in which algorithmic theft is the norm, and all the skills
            and talents that human artists have spent their lives accruing are
            utterly wasted. I’m here to provide a more optimistic take. I have
            three benefits I’d like to highlight.{" "}
          </p>
          <p>
            Art has been trending{" "}
            <a
              href="https://philamuseum.org/collection/object/92488"
              target="_blank"
              rel="noopener noreferrer"
            >
              more conceptual than technical
            </a>{" "}
            for well over a century now. The idea <i>behind</i> a piece of art
            has long been more crucial than the physical artifact itself.
            Luckily, AI is only likely to encroach on the technical side of
            art-making for at least the next decade or two. That leads me to the
            first two benefits of AI on the art world: 1) serious artists have
            ample time to consider what the future of this technology means for
            the trajectory of their respective practices, and 2) in the
            meantime, they can safely* consider incorporating AI tools into
            their current workflow in order to tighten certain feedback loops
            and iterate more rapidly (which, I think, leads to better art).{" "}
          </p>
          <p>
            *By ‘safely’ I mean ‘without being rendered totally useless’ not
            ‘without being stolen from’ cuz as we all know stealing has been an
            inevitable strategy in art since forever.{" "}
          </p>
          <p>
            The last benefit, though, captures the whole essence of my attitude
            towards AI in art. If you accept the superiority of concept over
            technique like I do (broadly construed I mean, the technique{" "}
            <i>could</i> be the concept), then you should consider this current
            rush of AI platforms to mean the potential for a whole new
            renaissance! Not only a renaissance for established artists with
            good ideas, but for non-artists who were previously barred from
            realizing their good ideas due to differences in ability or lack of
            time and/or means. Put simply, AI in art means that great taste will
            prevail and our lives will be enriched by all kinds of freaky new
            shit!{" "}
          </p>
          <p>
            Of course it’s not all roses. There’s the real possibility of AI
            empowering{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/is-cool-a-virtue"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="editorsNote">PSEUDO-COOL</span>{" "}
            </a>{" "}
            losers who previously couldn’t create anything worth a damn, thereby
            fueling their smug attitudes by convincing them they were geniuses
            all along. I don’t know how to hedge against that, but it’s a risk
            I’m personally willing to accept (
            <span className="editorsNote">TRUE-COOL</span> will always triumph).{" "}
          </p>
          <p>
            The parts of your brain that govern your particular tastes are
            referred to as your{" "}
            <a
              href="https://en.wikipedia.org/wiki/Salience_network"
              target="_blank"
              rel="noopener noreferrer"
            >
              salience network
            </a>{" "}
            . I recommend that you guard it and cultivate it, because it’s how
            you’re going to survive AI.{" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default ZuffGPT
